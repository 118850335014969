<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      @keydown.esc="closedialog(), $emit('openDrag')"
    >
      <!-- <v-card id="dialogpreview" class="ma-n4 pa-0">
        <v-toolbar color="primary" dark class="pa-0 ma-0">
          <v-btn icon dark @click="closedialog(),$emit('openDrag')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ filedata.file_name || filedata.filename }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="downloadfile(filedata)" :disabled="folderdata.permission_share_link === '01'">
              <v-icon>cloud_download</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="height: 100vh; width: 100vw; overflow-y: auto;" class="pa-0">
          <v-layout row wrap fill-height justify-center align-center class="pa-0">
            <v-flex xs6 :style="displayloading" class="text-center">
              <v-progress-circular
                size="100"
                width="8"
                background-color="light-blue lighten-4"
                color="success"
                indeterminate
                rounded
              ></v-progress-circular>
            </v-flex>
            <v-flex xs12 lg12 :style="displayiframe" class="text-center">
              <v-layout> -->
                <!-- ปุ่มเลื่อนซ้าย -->
                <!-- <v-flex class="ml-3 " lg1 xs1 fill-height justify-center align-center>
                  <div style="position: absolute; top: 50%; left: 4%;">
                    <v-btn v-if="disableleftclick !== true" @click="fn_sliefile_left()" x-small fab color="black" dark>
                      <v-icon>arrow_back_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>           
              <div v-if="typeviewer === 'image'">
                <div
                  v-if="folderdata.permission_share_link === '01' || folderdata.status_watermark === true"
                  class="watermarkedImg"
                  :data-watermark="folderdata.business_id ? (' บริษัท_' + folderdata.business_shared_th).repeat(10) : ''"
                  :data-watermark2="(' แชร์โดย_' + folderdata.name_shared_th + '/แชร์เมื่อ_' + folderdata.last_dtm).repeat(10)"
                  oncontextmenu="return false"
                >
                  <img class="modal-contentc pa-0" :src="filearraybuffer" alt="preview" style="max-width: 100vw;"/>
                </div>
                <div v-else>
                  <img class="modal-contentc pa-0" :src="filearraybuffer" alt="preview" style="max-width: 100vw;"/>
                </div>
              </div>
              <div v-else-if="typeviewer === 'pdf'">
                <div
                  v-if="folderdata.permission_share_link === '01'"
                  class="watermarked"
                  :data-watermark="folderdata.business_id ? (' บริษัท_' + folderdata.business_shared_th).repeat(10) : ''"
                  :data-watermark2="(' แชร์โดย_' + folderdata.name_shared_th + '/แชร์เมื่อ_' + folderdata.last_dtm).repeat(10)"
                  oncontextmenu="return false"
                >  -->
                  <!-- แผ่นใสปิดการคัดลอก -->
                  <!-- <v-expand-transition>
                    <v-overlay absolute color="lighten-1" style="width:1210px;" class="mt-9 mb-1"></v-overlay>
                  </v-expand-transition>
                  <iframe id="iframeratio" embedded="true" :src="rawbase64"></iframe>
                </div>
                <div v-else>
                  <iframe id="iframeratio" embedded="true" :src="rawbase64"></iframe>
                </div>
              </div>
              <div v-else-if="typeviewer === 'iframe'">
                <div
                  v-if="folderdata.permission_share_link === '01'"
                  class="watermarked"
                  :data-watermark="folderdata.business_id ? (' บริษัท_' + folderdata.business_shared_th).repeat(10) : ''"
                  :data-watermark2="(' แชร์โดย_' + folderdata.name_shared_th + '/แชร์เมื่อ_' + folderdata.last_dtm).repeat(10)"
                  oncontextmenu="return false"
                > -->
                  <!-- แผ่นใสปิดการคัดลอก -->
                  <!-- <v-expand-transition>
                    <v-overlay absolute color="lighten-1" style="width:1210px;" class="mt-9 mb-1"></v-overlay>
                  </v-expand-transition>
                  <iframe embedded="true" :src="rawbase64" style="height: 90vh; width: 99vw;"></iframe>
                </div>
                <div v-else>
                  <iframe embedded="true" :src="rawbase64" style="height: 90vh; width: 99vw;"></iframe>
                </div>
              </div>
              <v-flex v-else xs11 class="text-center">
              <v-icon size="200" color="grey">mdi-file-cancel</v-icon>
              <br />
              <br />
              <p style="font-size: 25px; color: grey">ไฟล์นี้ไม่รองรับในการดูตัวอย่าง</p>
            </v-flex> -->
              <!-- ปุ่มเลื่อนขวา -->
                <!-- <v-flex class="mr-3" lg1 xs1 fill-height justify-center align-center>
                  <div style="position: absolute; top: 50%; right:4%;">
                    <v-btn v-if="disablerightclick !== true" @click="fn_sliefile_right()" x-small fab color="black" dark>
                      <v-icon>arrow_forward_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs11 :style="displaynotcompatible" class="text-center">
              <v-icon size="200" color="grey">mdi-file-cancel</v-icon>
              <br />
              <br />
              <p style="font-size: 25px; color: grey">ไฟล์นี้ไม่รองรับในการดูตัวอย่าง</p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card> -->
      <v-card style="background-color:#e8eff2;">
        <v-toolbar color="#f2faff" dark class="pa-0 ma-0" oncontextmenu="return false">
          <v-btn color="primary" icon @click="closedialog(), $emit('openDrag')">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title style="color:#1976d2">{{ filedata.file_name || filedata.filename }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="primary" v-if="!officefilescompatible.includes(filedata.data_type)" :disabled="folderdata.permission_share_link === '01'" icon dark @click="downloadfile(filedata)"><v-icon color="primary">cloud_download</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="overflow-y:auto; overflow-x:hidden; position:fixed;">
          <v-layout row wrap
            fill-height
            justify-center
            align-center
            class="pa-0"
            style="height: 95vh;"
          >
            <v-flex xs6 :style="displayloading" class="text-center mt-n6" style="overflow-y:hidden;">
              <v-progress-circular
                size="100"
                width="8"
                background-color="light-blue lighten-4"
                color="primary"
                indeterminate
                rounded
              ></v-progress-circular>
            </v-flex>
            <v-flex xs12 lg12 :style="displayiframe" class="text-center mt-n6">
              <div v-if="typeviewer === 'image'">
                <!-- <div
                  v-if="folderdata.permission_share_link === '01'"
                  class="watermarkedImg testImage"
                  :data-watermark="' ' + (folderdata.business_id ? ( $t('publicpage.bizwatermark') + '_' + folderdata.business_shared_th).repeat(10) : '') + ' '"
                  :data-watermark2="(' ' + $t('publicpage.sharedby') + '_' + ($t('default') === 'th' ? folderdata.name_shared_th : folderdata.name_shared_eng) + '/' + $t('publicpage.shareddate') + '_' + folderdata.last_dtm).repeat(10)"
                  :data-watermark3="(folderdata.private_share === true) && dataCitizenProfile !== undefined
                   ? (' ' + $t('publicpage.openby') + '_' + ($t('default') === 'th' ? dataCitizenProfile.first_name_th : dataCitizenProfile.first_name_eng) + ' ' + ($t('default') === 'th' ? dataCitizenProfile.last_name_th : dataCitizenProfile.last_name_eng) + 
                   '/' + $t('publicpage.opendate') + '_' + folderdata.currentopendate).repeat(10) : ''"
                  oncontextmenu="return false"
                  >
                  <img class="mt-12 modal-contentc testImage pa-0" :src="filearraybuffer" alt="preview"/>
                </div>
                <div v-else> -->
                  <div oncontextmenu="return false" id="imageratio" style="overflow: hidden;">
                    <img :src="filearraybuffer"
                          alt="preview"
                          class="testImage"
                          style="max-width: 80%; max-height: 80%; position: relative;"/>
                  </div>
                <!-- </div> -->
                <div v-if="isActiveImageTooling" class="pl-n6 mt-n10" style="width: 99%; background-color: transparent; position: absolute; left: 0%; bottom: 6%;" >
                  <v-layout row justify-center align-center>
                    <v-card  class="px-2 py-1" >
                      <v-list-item dense>
                        <v-btn fab x-small :disabled="scalefarenhi === 1 ? true : false" depressed outlined class="mr-2" @click="scaleImage('OUT')">  
                          <v-icon>mdi-magnify-minus-cursor</v-icon>
                        </v-btn>
                        <v-btn fab x-small depressed outlined :disabled="scalefarenhi === 2.0 ? true : false" class="ml-4 mr-2" @click="scaleImage('IN')">
                          <v-icon>mdi-magnify-plus-cursor</v-icon>
                        </v-btn>
                        <v-divider vertical inset></v-divider>
                        <div :style="bgZoomColor" class="py-1 px-4 ml-2 mr-2">
                          <span class="font-weight-bold">
                            {{ Math.round((scalefarenhi / 1) * 100)  }} %
                          </span>
                        </div>
                        <v-divider vertical inset ></v-divider>
                        <v-btn fab x-small depressed outlined @click="rotateImage('L')" class="ml-4 mr-2" > 
                          <v-icon>mdi-rotate-left-variant</v-icon>
                        </v-btn>
                        <v-btn fab x-small depressed outlined class="ml-4" @click="rotateImage('R')">
                          <v-icon>mdi-rotate-right-variant</v-icon>
                        </v-btn>
                      </v-list-item>
                    </v-card>
                  </v-layout>
                </div>
              </div>
              <!-- style="height: 85vh; max-width: 85vw;" -->
              <div v-else-if="typeviewer === 'pdf'" class="pt-6 ml-n2 px-n6 pr-0" style="overflow-y:hidden; height: 92vh;">
                <!-- กรณีไม่เข้าเงื่อนไข -->
                <!-- <div
                  v-if="folderdata.permission_share_link === '01'"
                  class="watermarked"
                  :data-watermark="' ' + (folderdata.business_id ? ( $t('publicpage.bizwatermark') + '_' + folderdata.business_shared_th).repeat(10) : '') + ' '"
                  :data-watermark2="(' ' + $t('publicpage.sharedby') + '_' + ($t('default') === 'th' ? folderdata.name_shared_th : folderdata.name_shared_eng) + '/' + $t('publicpage.shareddate') + '_' + folderdata.last_dtm).repeat(10)"
                  :data-watermark3="(folderdata.private_share === true) && dataCitizenProfile !== undefined
                   ? (' ' + $t('publicpage.openby') + '_' + ($t('default') === 'th' ? dataCitizenProfile.first_name_th : dataCitizenProfile.first_name_eng) + ' ' + ($t('default') === 'th' ? dataCitizenProfile.last_name_th : dataCitizenProfile.last_name_eng) + 
                   '/' + $t('publicpage.opendate') + '_' + folderdata.currentopendate).repeat(10) : ''"
                  oncontextmenu="return false" -->
                  <!-- แผ่นใสปิดการคัดลอก -->
                  <!-- <v-expand-transition>
                    <v-overlay absolute color="lighten-1" style="width:1210px;" class="mt-9 mb-1">
                    </v-overlay>
                  </v-expand-transition> -->
                  <!-- <div class="pa-4 text-center"> -->
                    <!-- <vuePdf
                      v-for="i in pageCount"
                      :key="i"
                      :src="filearraybuffer"
                      :page="i"
                      class="ma-1"
                      style="display; inline-block; width:99vw;"
                    ></vuePdf> -->
                    <iframe id="iframeratio" embedded="true" :src="rawbase64" style="height: 90vh; max-width: 99vw; width: 100vw;"></iframe>
                  <!-- </div> -->
                <!-- </div>
                <div v-else>
                  <iframe id="iframeratio" embedded="true" :src="rawbase64" style="height: 90vh; max-width: 99vw; width: 100vw;"></iframe>
                </div> -->
              </div>
              <div align-center class="ml-0 mr-0" v-else-if="typeviewer === 'mp4'|| typeviewer === 'mov'">
                <video-player align-center class="video-player-box"                     
                  ref="videoPlayer"
                  :options="playerOptions">
                </video-player>
              </div>
              <div v-else-if="typeviewer === 'iframe'" class="pt-6" style="overflow-y:hidden; height: 92vh;">
                <div
                  v-if="folderdata.permission_share_link === '01'"
                  class="watermarked"
                  :data-watermark="' ' + (folderdata.business_id ? ( $t('publicpage.bizwatermark') + '_' + folderdata.business_shared_th).repeat(10) : '') + ' '"
                  :data-watermark2="(' ' + $t('publicpage.sharedby') + '_' + ($t('default') === 'th' ? folderdata.name_shared_th : folderdata.name_shared_eng) + '/' + $t('publicpage.shareddate') + '_' + folderdata.last_dtm).repeat(10)"
                  :data-watermark3="(folderdata.private_share === true) && dataCitizenProfile !== undefined
                   ? (' ' + $t('publicpage.openby') + '_' + ($t('default') === 'th' ? dataCitizenProfile.first_name_th : dataCitizenProfile.first_name_eng) + ' ' + ($t('default') === 'th' ? dataCitizenProfile.last_name_th : dataCitizenProfile.last_name_eng) + 
                   '/' + $t('publicpage.opendate') + '_' + folderdata.currentopendate).repeat(10) : ''"
                  oncontextmenu="return false"
                >
                  <!-- แผ่นใสปิดการคัดลอก -->
                  <!-- <v-expand-transition>
                    <v-overlay absolute color="lighten-1" style="width:1210px;" class="mt-9 mb-1">
                    </v-overlay>
                  </v-expand-transition> -->
                  <iframe embedded="true" :src="rawbase64" style="height: 89vh; max-width: 98vw; width: 100vw;"></iframe>
                </div>
                <div v-else>
                  <iframe embedded="true" :src="rawbase64" style="height: 89vh; max-width: 98vw; width: 100vw;"></iframe>
                </div>
              </div>
            </v-flex>
            <v-flex xs11 :style="displaynotcompatible" class="text-center pt-12">
              <v-icon size="200" color="grey">mdi-file-cancel</v-icon>
              <br />
              <br />
              <p style="font-size: 25px; color: grey">{{$t("publicpage.notpreview")}}</p>
              <v-btn class="ma-2" color="primary" dark @click="downloadfile(filedata)"
                >{{ $t("publicpage.dowload") }} <v-icon class="pl-2">cloud_download</v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload=false"
      :percent="percent"
      :namedowload="namedowload"
     ></dialogsnackbermultidowload>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowload = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowload}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vuePdf from "vue-pdf";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
const dialogsnackbermultidowload =() => import("../optional/dialog-snackbarmultidowmload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["show", "userid", "filedata_1", "arrayfile", "folderdata"],
  components: { vuePdf,dialogsnackbermultidowload, videoPlayer, },
  data: function() {
    return {
      // NEWWW
      isActiveImageTooling: true,
      fit_ontop_container: false,
      fit_onbottom_container: false,
      fit_onleft_container: false,
      fit_onright_container: false,
      isDragging_image: false,
      isOverTop: false,
      isOverLeft: false,
      isOverTopAndLeft: false,
      rememberScale: 0,
      initialMouseX: 0,
      initialMouseY: 0,
      translate_posX: 0,
      translate_posY: 0,
      overflowLeft: 0,
      overflowTop: 0,
      imageItems: "",
      // 
      disableleftclick: false,
      disablerightclick: false,
      filedata: {},
      opensnackbarmultidownload:false,
      percent:0,
      namedowload:'',
      timeout: 6000,
      percentCompleted: 0,
      snackbardowload:false,
      displayloading: "",
      displayiframe: "display: none",
      displaynotcompatible: "display: none",
      rawbase64: "",
      scale: "page-width",
      compatibletype: "",
      compatible: false,
      pageCount: 0,
      loading: true,
      filearraybuffer: "",
      SizeImage:
        window.innerHeight > window.innerWidth
          ? window.innerWidth
          : window.innerHeight,
      filename: "",
      createprogress: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" }
      ],
      right: null,
      drawer: null,
      imagefilescompatible: ["jpg", "jpeg", "png", "gif"],
      officefilescompatible: ["xlsx", "pptx", "xls", "ppt", "doc", "docx"],
      extensionsfilescompatible: [
        "jpeg",
        "jpg",
        "png",
        "gif",
        "txt",
        // "xml",
        "pdf",
        "xlsx",
        "pptx",
        "xls",
        "ppt",
        "doc",
        "docx",
        "mp4",
        "mov"
      ],
    
      typeviewer: "",
      rotatedegree: 0,
      scalefarenhi: 1,
      link_video: "",
      playerOptions: {
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: "/static/images/author.jpg",
        height: window.innerHeight - 150,
        width:  window.innerWidth - 70
      },
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          // this.checkcompatible();
          this.rotatedegree = 0;
          this.scalefarenhi = 1;
          setTimeout(() => {
              this.detectDeviceType()
            }, 200)
          return this.show;
          this.$emit('closeDrag')
        } else {
          this.isActiveImageTooling = true
          this.isDragging_image= false
          this.initialMouseX= 0
          this.initialMouseY = 0
          this.translate_posX = 0
          this.translate_posY = 0
          this.rememberScale = 0
          this.rotatedegree = 0
          this.scalefarenhi = 1
          this.overflowLeft = 0
          this.overflowTop =  0
          this.fit_onleft_container = false
          this.fit_onbottom_container = false
          this.fit_ontop_container = false
          this.fit_onright_container = false
          this.imageItems = ""
          return this.show;
        }
      },
      set(value) {
        if (!value) {
          this.$emit("closepreviewfile");
        }
      }
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.filename.$dirty) return errors;
      !this.$v.filename.required && errors.push("กรุณากรอกชื่อ ไฟล์/โฟลเดอร์");
      return errors;
    },
    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    bgZoomColor () {
      return this.$vuetify.theme.dark ? `border-radius: 20px; padding-top: -10px;` : `border-radius: 20px; padding-top: -10px; background-color: #f0f0f0;`
    },
  },
   watch: {
   loading: function(newval, oldval) {
    console.log("newval", newval);
    console.log("this.compatible", this.compatible);
      if (newval == true) {
        this.displayloading = "";
        this.displayiframe = "display: none";
        this.displaynotcompatible = "display: none";
      } else if (newval == false && this.compatible == true) {
        this.displayloading = "display: none";
        this.displayiframe = "";
        this.displaynotcompatible = "display: none";
      } else if (newval == false && this.compatible == false) {
        this.displayloading = "display: none";
        this.displayiframe = "display: none";
        this.displaynotcompatible = "";
      }
      },
        show: function(value) {
     
      if (value === false) {
        this.rawbase64 = "";
        this.loading = true;
        this.filearraybuffer = "";
        this.compatibletype = "";
        this.compatible = false;
        this.pageCount = 0;
        this.typeviewer = "";
        this.displayloading = "";
        this.displayiframe = "display: none";
        this.displaynotcompatible = "display: none";
        this.$emit("openDrag");
        //this.closedialog();
      } else {
        console.log("arrayfile show", this.arrayfile);
        this.loading = true;
        //this.checkcompatible();
        this.fn_showallfile();

        this.$emit("closeDrag");
       
      }
     
    },
  },
  methods: {
    touchDetect_event () {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    },
    detectDeviceType() {
      if(['xs', 'sm',  'md'].includes(this.$vuetify.breakpoint.name)){
        this.isActiveImageTooling = false
        return
      } else {
        var userAgent = navigator.userAgent;
        var isMobileOS = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        var isWindows = /Windows NT/i.test(userAgent);
        if((isWindows || /Macintosh/i.test(userAgent)) && !this.touchDetect_event() && !isMobileOS ){ // detect ที่เป็น desktop ทั้ง window และ macos แต่จะต้องไม่ใช่ device
          this.isActiveImageTooling = true
        } else if (/Macintosh/i.test(userAgent) && this.touchDetect_event()) {
          this.isActiveImageTooling = false
        } else {
           this.isActiveImageTooling = true
        }
      }
    },
    scaleImage (status) {
      this.imageItems = document.getElementsByClassName('testImage')[0]
      const canvasId = document.getElementById("imageratio")
      if(status === 'IN'){
        this.scalefarenhi += 0.2
        this.scalefarenhi = Number(this.scalefarenhi.toFixed(1))
      } else {
        this.scalefarenhi -= 0.2
        this.scalefarenhi = Number(this.scalefarenhi.toFixed(1))
      }
      if(this.rememberScale === 0){
        this.imageItems.style.transform = `scale(${this.scalefarenhi}) translate(${0}px, ${0}px) rotate(${this.rotatedegree}deg)`
      } else {
        this.translate_posX = 0
        this.translate_posY = 0
        this.imageItems.style.transform = `scale(${this.scalefarenhi}) translate(${this.translate_posX}px, ${this.translate_posY}px) rotate(${this.rotatedegree}deg)`
      }
      const containerRect = canvasId.getBoundingClientRect();
      const ImageReact = this.imageItems.getBoundingClientRect();
      this.overflowLeft = (containerRect.left - ImageReact.left) + 2
      this.overflowTop = (containerRect.top - ImageReact.top) + 2
      this.isOverTop = (ImageReact.top < containerRect.top)
      this.isOverLeft = (ImageReact.left < containerRect.left)
      this.isOverTopAndLeft = (ImageReact.top < containerRect.top && ImageReact.left < containerRect.left)
      this.NewCreateEventBoundaries(this.imageItems)
    },
    rotateImage (status) {
      const canvasId = document.getElementById("imageratio")
      this.imageItems = document.getElementsByClassName('testImage')[0]
      this.translate_posX = 0
      this.translate_posY = 0
      this.rememberScale = 0
      this.scalefarenhi = 1
      if(status === 'R'){
        this.rotatedegree += 90
        if(this.rotatedegree === 360){
          this.rotatedegree = 0
        }
      } else {
        this.rotatedegree -= 90
        if(this.rotatedegree === -360){
          this.rotatedegree = 0
        }
      }
      
      this.imageItems.style.transform = `rotate(${this.rotatedegree}deg) translate(${this.translate_posX}px, ${this.translate_posY}px)`
      const containerRect = canvasId.getBoundingClientRect();
      const ImageReact = this.imageItems.getBoundingClientRect();
      this.overflowLeft = (containerRect.left - ImageReact.left) + 2
      this.overflowTop = (containerRect.top - ImageReact.top) + 2
      this.isOverTop = (ImageReact.top < containerRect.top)
      this.isOverLeft = (ImageReact.left < containerRect.left)
      this.isOverTopAndLeft = (ImageReact.top < containerRect.top && ImageReact.left < containerRect.left)
      this.NewCreateEventBoundaries(this.imageItems)
    },
    // ฟังค์ชั่นใหม่ (ใช้งาน)
    NewCreateEventBoundaries (elementImg) {
      if(this.isOverLeft || this.isOverTop || this.isOverTopAndLeft) {
        elementImg.style.cursor = "grab"
      } else {
        elementImg.style.cursor = "auto"
      }
      if(this.rememberScale !== 0) {
        if(this.scalefarenhi < this.rememberScale) {
            elementImg.style.transform = `translate(0px, 0px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            elementImg.style.cursor = "auto"
            this.rememberScale = 0
            this.translate_posX = 0
            this.translate_posY = 0
            this.fit_onleft_container = false
            this.fit_onright_container = false
            this.fit_ontop_container = false
            try{
              if(this.screenSize >= 500) {
                elementImg.removeEventListener("mousedown", this.fn_mousedown)
                elementImg.removeEventListener("mousemove", this.fn_newmousemoving)
              } else {
                elementImg.removeEventListener("touchstart", this.fn_mousedown)
                elementImg.removeEventListener("touchmove", this.fn_newmousemoving)
              }
            } catch (err) {
              console.log("err remvoe ");
            }
        } else {
            // ฟิตซ้ายขวา
            if(this.overflowLeft > 0 && this.overflowTop <= 0){
                if(this.fit_onleft_container || this.fit_onright_container) {
                  this.translate_posX = this.fit_onright_container ? -this.overflowLeft : this.overflowLeft
                  elementImg.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                }
            }

            // ฟิตบนล่าง
            if(this.overflowTop > 0 && this.overflowLeft <= 0) {
                  if(this.fit_ontop_container || this.fit_onbottom_container) {
                    this.translate_posY = this.fit_ontop_container ? this.overflowTop : -this.overflowTop
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                  } else {
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                  }
            }

            if(this.overflowTop > 0 && this.overflowLeft > 0) {
                  if(this.fit_ontop_container && this.fit_onleft_container) {
                    this.translate_posY = this.overflowTop
                    this.translate_posX = this.overflowLeft
                    
                  } else if (this.fit_ontop_container && this.fit_onright_container) {
                    this.translate_posY = this.overflowTop
                    this.translate_posX = -this.overflowLeft
                  } else if (this.fit_onbottom_container && this.fit_onleft_container) {
                    this.translate_posY = -this.overflowTop
                    this.translate_posX = this.overflowLeft
                  } else if (this.fit_onbottom_container && this.fit_onright_container) {
                    this.translate_posY = -this.overflowTop
                    this.translate_posX = -this.overflowLeft
                  } else if (this.fit_ontop_container || this.fit_onbottom_container) {
                    this.translate_posY = this.fit_ontop_container ? this.overflowTop : -this.overflowTop
                  } else if (this.fit_onright_container || this.fit_onleft_container) {
                    this.translate_posX = this.fit_onright_container ? this.overflowLeft : -this.overflowLeft
                  }
                  elementImg.style.transform = `translate(${this.translate_posX}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            }

            // เปิด Event mousemove and touchmove
            if(this.screenSize >= 500){
              elementImg.addEventListener('mousedown', this.fn_mousedown);
              elementImg.addEventListener('mousemove', this.fn_newmousemoving )
              elementImg.addEventListener('mouseup', (event) => {
                this.isDragging_image = false
              });
            } else {
              this.imageItems.addEventListener('touchstart', this.fn_mousedown);
              this.imageItems.addEventListener('touchmove', this.fn_newmousemoving)
              this.imageItems.addEventListener('touchend', (event) => {
                  this.isDragging_image = false
                });
          }

        }
      } else {
        if (this.overflowLeft > 0 || this.overflowTop > 0) {
          this.rememberScale = this.scalefarenhi
          // เปิด Event mousemove and touchmove
          if(this.screenSize >= 500){
            this.imageItems.addEventListener('mousedown', this.fn_mousedown);
            this.imageItems.addEventListener('mousemove', this.fn_newmousemoving)
            this.imageItems.addEventListener('mouseup', (event) => {
                this.isDragging_image = false
                this.imageItems.style.cursor = "grab"
              });
            this.imageItems.addEventListener("mouseleave", () => {
              this.isDragging_image = false
              this.imageItems.style.cursor = "grab"
            })
          } else {
            this.imageItems.addEventListener('touchstart', this.fn_mousedown);
            this.imageItems.addEventListener('touchmove', this.fn_newmousemoving)
            this.imageItems.addEventListener('touchend', (event) => {
                this.isDragging_image = false
              });
          }
        } else {
            elementImg.style.transform = `translate(0px, 0px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            elementImg.style.cursor = "auto"
            this.translate_posX = 0 
            this.translate_posY = 0
            this.rememberScale = 0
            try{
              if(this.screenSize >= 500) {
                elementImg.removeEventListener("mousedown", this.fn_mousedown)
              } else {
                elementImg.removeEventListener("touchstart", this.fn_mousedown)
                elementImg.removeEventListener("touchmove", this.fn_newmousemoving)
              }
            } catch (err) {
              console.log("err remvoe ");
            }
        }
      }
    },
    // ใช้งาน (**อยู่ใน NeCreateEventBoundaries )
    fn_mousedown (event) {
      this.imageItems.style.cursor = "grabbing"
      this.isDragging_image = true
      this.initialMouseX = this.screenSize >= 500 ? event.clientX - this.translate_posX : event.targetTouches["0"].clientX - this.translate_posX
      this.initialMouseY = this.screenSize >= 500 ? event.clientY - this.translate_posY : event.targetTouches["0"].clientY - this.translate_posY
    },

    // ใช้งาน (**อยู่ใน NeCreateEventBoundaries )
    fn_newmousemoving (event) {
      if(this.isDragging_image){
        if(this.isOverLeft && !this.isOverTopAndLeft){
          let deltaX = this.screenSize >= 500 ? event.clientX - this.initialMouseX : event.targetTouches["0"].clientX - this.initialMouseX
          this.translate_posX = deltaX
          if(this.translate_posX < this.overflowLeft){
            if(Math.abs(this.translate_posX) >= this.overflowLeft){
              this.imageItems.style.transform = `translate(${-this.overflowLeft}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = -this.overflowLeft
              //document.getElementById("deltaX").innerHTML = `DeltaX ${-overflowLeft}`
              this.fit_onright_container = true
            } else {
              this.imageItems.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              //document.getElementById("deltaX").innerHTML = `DeltaX ${this.translate_posX}`
              this.fit_onleft_container = false
              this.fit_onright_container = false
            }
          } else {
            this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            this.translate_posX = this.overflowLeft
            //document.getElementById("deltaX").innerHTML = `DeltaX ${overflowLeft}`
            this.fit_onleft_container = true
          }
        }
      
        if(this.isOverTop && !this.isOverTopAndLeft) {
            let deltaY = this.screenSize >= 500 ? event.clientY - this.initialMouseY : event.targetTouches["0"].clientY - this.initialMouseY
            this.translate_posY = deltaY
            if(this.translate_posY < this.overflowTop){
              if(Math.abs(this.translate_posY) >= this.overflowTop){
                this.imageItems.style.transform = `translate(${0}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
                this.fit_onbottom_container = true
              } else {
                this.imageItems.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.fit_ontop_container = false
                this.fit_onbottom_container = false
              }
            } else {
                this.imageItems.style.transform = `translate(${0}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
                this.fit_ontop_container = true
            }
        }

        if(this.isOverTopAndLeft) {
            let deltaY = this.screenSize >= 500 ? event.clientY - this.initialMouseY : event.targetTouches["0"].clientY - this.initialMouseY
            let deltaX = this.screenSize >= 500 ? event.clientX - this.initialMouseX : event.targetTouches["0"].clientX - this.initialMouseX
            this.translate_posY = deltaY
            this.translate_posX = deltaX
            if(this.translate_posY >= this.overflowTop && this.translate_posX >= this.overflowLeft) {
              this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = this.overflowLeft
              this.translate_posY = this.overflowTop
            } 
            // else
            // บนซ้ายยย / ล่างซ้าย 
            if (Math.abs(this.translate_posY) >= this.overflowTop && Math.abs(this.translate_posX) >= this.overflowLeft) {
              if(this.translate_posY > 0) {
                this.imageItems.style.transform = `translate(${this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
              } else {
                this.imageItems.style.transform = `translate(${this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
              }
              this.translate_posX = this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft
              if(this.translate_posX > 0 && this.translate_posY > 0){
                this.fit_ontop_container = true
                this.fit_onleft_container = true
                this.fit_onbottom_container = false
                this.fit_onright_container = false
                console.log("บนซ้ายยยยยยยยยยยยยยยยยยยย", this.translate_posY)
              } 
              else if (this.translate_posX > 0 && this.translate_posY < 0) {
                this.fit_onbottom_container = true
                this.fit_onleft_container = true
                this.fit_onright_container = false
                this.fit_ontop_container = false
                console.log("ล่างซ้ายยยยยยยยยยยยยยยยยยยย", this.translate_posY)
              } 
              else if (this.translate_posX < 0 && this.translate_posY > 0) {
                this.fit_onright_container = true
                this.fit_ontop_container = true
                this.fit_onbottom_container = false
                this.fit_onleft_container = false
                console.log("บนขวาาาาาาาาาาาาาาาาาาาาาาาาา", this.translate_posY)
              } 
              else if (this.translate_posX < 0 && this.translate_posY < 0) {
                this.fit_onright_container = true
                this.fit_onbottom_container = true
                this.fit_onleft_container = false
                this.fit_ontop_container = false
              }
            }
            // บน / ล่าง
            else if (Math.abs(this.translate_posY) >= this.overflowTop) {
              if(this.translate_posY > 0) {
                this.imageItems.style.transform = `translate(${deltaX}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
                this.fit_onright_container = false
                this.fit_onbottom_container = false
                this.fit_onleft_container = false
                this.fit_ontop_container = true
                console.log("บนนนนนนนนนนนนนนน")
              } else {
                this.imageItems.style.transform = `translate(${deltaX}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
                this.fit_onright_container = false
                this.fit_onbottom_container = true
                this.fit_onleft_container = false
                this.fit_ontop_container = false
                console.log("ล่างงงงงงงงงงงงงงงงง")
              }
            } 
            // ซ้าย / ขวา
            else if (Math.abs(this.translate_posX) >= this.overflowLeft) {
              if(this.translate_posX > 0) {
                this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = this.overflowLeft
              } else {
                this.imageItems.style.transform = `translate(${-this.overflowLeft}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = -this.overflowLeft
              }
            } 
            else {
              this.imageItems.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.fit_onbottom_container = false
              this.fit_onleft_container = false
              this.fit_onright_container = false
              this.fit_ontop_container = false
            }
        }
      }
    },
    async checklastdtm(_fileid) {
      try{
      let payload = {
        account_id: this.dataAccountId,
        file_id: _fileid,
      };
      // this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/last_view_dtm", payload).then((response) => {
      //   if (response.data.status === "OK") {
      //     console.log("response", response.data.status);
      //     if (this.filedata.status_lock_file === "Y") {
      //       this.opendialog_checklog = true;
      //       console.log("opendialog_checklog", this.opendialog_checklog);
      //     }
      //   } else {
      //console.log("testtttt", _fileid);
      //   }
      let auth = await gbfGenerate.generateToken();
      this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/last_view_dtm", payload,{headers: { Authorization: auth.code },}).then((response) => {
        if (response.data.status === "OK") {
          console.log("fileeeeeeeeeeeeeeeeeeee", this.filedata);
          if(this.filedata.status_lock_data){
          if (this.filedata.status_lock_data === "Y") {
            this.opendialog_checklog = true;
            //console.log("opendialog_checklog", this.opendialog_checklog);
          }
         else {      
          console.log("response", response.data);
        }
        }else{
          console.log("response", response.data);
        
        }
        }
      });
      }catch(error){
        console.log(error);
      }
    },
    async  fn_printPDF_single(){
      
      let listfile="";
      this.displayloading = "";
      this.loading = true
      let i
      // for(i = 0 ; i< this.filemultipledownload.length ; i++){
      //   if(this.filemultipledownload[i]["file_type"] !== "pdf"){
      //     Toast.fire({
      //       icon: "error",
      //       title: "โปรดเลือกเฉพาะไฟล์ PDF",
      //     });
      //     notpdf = true;
      //     this.loaddataprogress_print = false;
      //   }else if(this.filemultipledownload[i]["status_lock_data"] === "Y"){ 
      //     Toast.fire({
      //       icon: "error",
      //       title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
      //     });
      //     notpdf = true;
      //     this.loaddataprogress_print = false;
      //   }
      //   else{
       listfile = "&list_file="+this.filedata.file_id
      //  //console.log(listfile);
      //   }
      // }
     if(this.filedata.file_type === "pdf"){
      let auth = await gbfGenerate.generateToken();
      
      
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/merge_pdf?account_id=" +
          this.dataAccountId +
          "&user_id="+
          this.dataUsername+
          "&Authorization="+
          auth.code+
          listfile
           this.axios
            .get(url).then((response) => {
              //console.log(response.data.status);
              if(response.data.status !== "ER"){
                this.displayloading = "display: none";
                this.loading = false
                console.log(this.displayloading);
              //  this.filemultipledownload = [];
              //   this.loaddataprogress_print = false;
                 window.open(url);
              }else{
               this.displayloading = "display: none";
               this.loading = false
                Toast.fire({
            icon: "error",
            title: "ไม่สามารถพิมพ์ไฟล์เสียได้",
          });
              }
            });
         
     }     
    },
    fn_clearImageZoomEvent () {
      this.scalefarenhi = 1
      this.rememberScale = 0
      this.rotatedegree = 0
      this.translate_posX = 0
      this.translate_posY = 0
      this.initialMouseX = 0
      this.initialMouseY = 0
      this.overflowTop = 0
      this.overflowLeft = 0
      this.isDragging_image = false
      this.fit_ontop_container = false
      this.fit_onbottom_container = false
      this.fit_onleft_container = false
      this.fit_onright_container = false
      const imageItems = document.getElementsByClassName('testImage')[0]
      imageItems.style.transform = ""
      if(this.screenSize >= 500){
        imageItems.removeEventListener("mousedown", this.fn_mousedown)
        imageItems.removeEventListener("mousemove", this.fn_newmousemoving)
      } else {
        imageItems.removeEventListener("mousedown", this.fn_mousedown)
        imageItems.removeEventListener("touchstart", this.fn_newmousemoving)
      }
      
    },
    fn_sliefile_right() {
      this.donotpreview = false;
      //console.log("right",this.objIndex);
      this.checklastdtm();
      
      let objIndex = (this.objIndex += 1);
      this.rawbase64 = "";
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = "";
      this.compatible = false;
      this.pageCount = 0;
      this.typeviewer = "";
      this.displayloading = "";
      this.displayiframe = "display: none";
      // this.displaynotcompatible = "display: none";
      //console.log("f",objIndex,(this.raw_arrayfile.length-1));
      try{
        this.fn_clearImageZoomEvent()
      } catch (err) {
        console.log("Error Catch ")
      }

      if (objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      } else {
        this.disablerightclick = false;
      }

      if (objIndex === 0) {
        this.disableleftclick = true;
      } else {
        this.disableleftclick = false;
      }
      this.filedata = this.raw_arrayfile[objIndex];
      console.log("fff");
      this.checklastdtm(this.filedata.file_id);
      console.log("this.filedata-------",this.filedata);
      this.checkcompatible();
      if(this.filedata.file_type === "shortcut"){
        this.fn_load_shortcut(this.filedata.file_id)
      }
    },
    fn_sliefile_left() {
      this.donotpreview = false;
       console.log("fff");
      this.checklastdtm();
      //console.log("left",this.objIndex);
      let objIndex = (this.objIndex -= 1);
      this.rawbase64 = "";
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = "";
      this.compatible = false;
      this.pageCount = 0;
      this.typeviewer = "";
      this.displayloading = "";
      this.displayiframe = "display: none";
      // this.displaynotcompatible = "display: none";
      try{
        this.fn_clearImageZoomEvent()
      } catch (err) {
        console.log("Error ")
      }
      if (objIndex === 0) {
        this.disableleftclick = true;
      } else {
        this.disableleftclick = false;
      }
      if (objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      } else {
        this.disablerightclick = false;
      }
      // objIndex = this.arrayfile.findIndex(
      //   (obj) => obj.file_id === this.filedata.file_id)
      this.filedata = this.raw_arrayfile[objIndex];
       console.log("fff");
      this.checklastdtm(this.filedata.file_id);
      this.checkcompatible();
      //this.fn_showallfile();
      if(this.filedata.file_type === "shortcut"){
        this.fn_load_shortcut(this.filedata.file_id)
      }
    },
    fn_showallfile() {
       if(this.filedata_1.file_type === "shortcut"){
         //console.log("shortcut");
        this.fn_load_shortcut();
        }
      this.donotpreview = false;
      //console.log("ggggggggggggggggggg");
      //console.log("arrayyyyyyyy",this.arrayfile);
      this.raw_arrayfile = this.arrayfile.filter((type) => type.file_type !== "folder");
      //console.log("this.raw_arrayfile",this.raw_arrayfile);
      // if(this.test === true){
      //console.log("filedata_1 fn_showallfile",this.filedata_1);
      //console.log(this.raw_arrayfile);
      //console.log(this.objIndex);
      this.objIndex = this.raw_arrayfile.findIndex((obj) => obj.file_id === this.filedata_1.file_id);
      this.filedata = this.raw_arrayfile[this.objIndex];
      //console.log("filedata +++++++",this.filedata.file_icon);
      this.checklastdtm(this.filedata.file_id);
      this.checkcompatible();
      //console.log(" checkcompatible");
      this.test = false;
      if (this.objIndex === 0) {
        this.disableleftclick = true;
      }
      if (this.objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      }
      // }
    },
    checkcompatible() {
      //console.log("checkcompatible");
      try{
      if (this.$router.app["_route"]["name"] !== "myinbox_sourcesystem") {
        console.log(this.filedata.file_name);

        
        if(this.filedata.file_name === ".DS_Store"){
          this.displayloading = "display: none";
          this.displayiframe = "";
          this.displaynotcompatible = "display: none";
        }else{
          let filename = this.filedata.file_name.split(".");
        if (
          this.extensionsfilescompatible.includes(
            filename[filename.length - 1].toLowerCase()
          ) === true
        ) {
          this.compatible = true;
          this.setiframeviewer(filename[filename.length - 1].toLowerCase());
        } else {
          //console.log("fgyuhu");
          // this.compatible = false;
          // this.loading = false;
          this.displayloading = "display: none";
          this.displayiframe = "display: none";
          this.displaynotcompatible = "";
        }
      }
      }
      }catch(error){
        console.log("fff",error);
      }
    },
    setiframeviewer(type) {
      console.log("setiframeviewer");
      this.rawbase64 = "";
      if (!this.officefilescompatible.includes(type)) {
        if (this.imagefilescompatible.includes(type) === true) {
          this.typeviewer = "image";
          this.setimageviewer();
        } else if (type === "pdf") {
          this.typeviewer = "pdf";
          this.setpdfviewer();
        }else if (type === "mp4"){
        console.log("mp4",type);
          this.typeviewer = "mp4";
          this.setmp4viewer();
        } else if (type === "mov"){
          console.log("mov",type);
          this.typeviewer = "mov";
          this.setmp4viewer();
        } else {
          this.typeviewer = "iframe";
          this.rawbase64 =
            // "https://docs.google.com/gview?url=" +
            "https://docs.google.com/a/google.com/viewer?url=" +
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/preview/" +
            this.userid +
            "/" +
            this.filedata.file_id +
            "&embedded=true";
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        }
      } else if (type === "pdf") {
        this.typeviewer = "pdf";
        this.setpdfviewer();
      } else if (type === "mp4"){
        console.log("mp4",type);
        this.typeviewer = "mp4";
        this.setmp4viewer();
      } else if (type === "mov"){
        console.log("mov",type);
        this.typeviewer = "mov";
        this.setmp4viewer();
      } else {
        this.typeviewer = "iframe";
        // this.rawbase64 = "https://docs.google.com/gview?url=" +
        //  process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
        //  "/api/preview/" +
        //  this.userid +
        //  "/" + 
        //  this.filedata.file_id +
        // "&embedded=true";

        // ใช้ปัจจุบัน
        // let encodeURI = process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.userid + "/" + this.filedata.file_id
        // this.rawbase64 =  "/documentserver/index.html?filename=" + encodeURIComponent(this.filedata.file_name || this.filedata.filename) + "&source=" + encodeURIComponent(encodeURI);
        
        // ชั่วคราว
        this.rawbase64 =
          "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/preview/" +
          this.userid +
          "/" +
          this.filedata.file_id;
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    async setimageviewer() {
      console.log("this.filedata", this.filedata);
      let auth = await gbfGenerate.generateToken();
      // let payload = {
      //   user_id: this.userid,
      //   file_id: this.filedata.file_id
      // };
      let url;
      if (this.filedata.status_watermark === "N" && this.folderdata.permission_share_link !== '01') {
        url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          "Shared link" +
          "&file_id=" +
          this.filedata.file_id;
      } else {
        url = 
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
          "/api/v1/share_link/download_file?file_id="+ 
          this.filedata.file_id +
          "&language=" +
          this.$t("default") +
          "&link=" +
          this.$route.query.id;
        if (this.folderdata.private_share === true) {
          url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
        }
      }
      await this.axios
        .get(url, {
          withCredentials: false,
          responseType: "arraybuffer",
          headers: { Authorization: auth.code },
        },
        )
        .then(response => {
          const buffer = Buffer.from(response.data, "base64");
          let u8 = new Uint8Array(buffer);
          let b64encoded = btoa(
            [].reduce.call(
              new Uint8Array(buffer),
              function(p, c) {
                return p + String.fromCharCode(c);
              },
              ""
            )
          );
          let mimetype = "image/png";
          this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch(error => {
          console.log(error);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    setpdfviewer() {
      console.log("test");
      let url;
      if (this.filedata.status_watermark === "N" && this.folderdata.permission_share_link !== '01') {
        // let payload = {
        //   user_id: this.filedata.user_id,
        //   file_id: this.filedata.file_id
        // };
        url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/v2/download_file?user_id=" + this.userid + "&file_id=" + this.filedata.file_id;
      } else {
        url = 
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
          "/api/v1/share_link/download_file?file_id="+ 
          this.filedata.file_id +
          "&language=" +
          this.$t("default") +
          "&link=" +
          this.$route.query.id;
        if (this.folderdata.private_share === true) {
          url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
        }
      }
      this.rawbase64 = process.env.VUE_APP_UI+ "/extension_pdfjs/viewer.html?file=" + encodeURIComponent(url)

          setTimeout(() => {
            this.loading = false;
          }, 1000);
      console.log(url);
      // this.axios
      //   .get(
      //      process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.userid + "/" + this.filedata.file_id,
      //     {
      //       withCredentials: false,
      //       responseType: "arraybuffer"
      //     }
      //   )
      //   .then(response => {
      //     this.loading = false;
      //     const buffer = Buffer.from(response.data, "base64");
      //     let u8 = new Uint8Array(buffer);
      //     let b64encoded = btoa(
      //       [].reduce.call(
      //         new Uint8Array(buffer),
      //         function(p, c) {
      //           return p + String.fromCharCode(c);
      //         },
      //         ""
      //       )
      //     );

      //     let mimetype = "application/pdf";
      //     this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
      //     var loadingTask = vuePdf.createLoadingTask(
      //       "data:" + mimetype + ";base64," + b64encoded
      //     );

      //     this.filearraybuffer = loadingTask;
      //     this.filearraybuffer.promise.then(vuePdf => {
      //       this.pageCount = vuePdf.numPages;
      //     });

      //     // setTimeout(() => {
      //     //   this.loading = false;
      //     // }, 1000);
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     setTimeout(() => {
      //       this.loading = false;
      //     }, 1000);
      //   });
    },
    async setmp4viewer(){
      // console.log("setmp4viewer",this.filedata);
      let payload = {
        file_id: this.filedata.file_id,
      }
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/view_video", payload, {          
        },)
        .then((response) => {
          //console.log("response",response);
          if(response.data.status === "OK"){
           console.log("response",response.data.result);
           this.link_video = response.data.result
           this.playerOptions.sources[0]["src"] = this.link_video
           this.displayiframe = "";
           this.displayloading = "display: none";
          }
        })
        .catch((error) => {
        });
    },
    async getpreview() {
      let payload = {
        user_id: this.userid,
        file_id: this.filedata.file_id
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
        payload,
        {
          headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                if (progresspercent >= 100) {
                  this.loading = false;
                } else {
                  this.loading = true;
                }
              },

            withCredentials: false,
            responseType: "arraybuffer"
        }
      );
      try {
        const buffer = Buffer.from(response.data, "base64");
        let u8 = new Uint8Array(buffer);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(buffer),
            function(p, c) {
              return p + String.fromCharCode(c);
            },
            ""
          )
        );
        let mimetype = "image/png";
        console.log(b64encoded);

        this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
        this.loading = false;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getpreviewpdf() {
      let payload = {
        user_id: this.userid,
        file_id: this.filedata.file_id
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false,
          responseType: "arraybuffer"
        }
      );
      try {
        const buffer = Buffer.from(response.data, "base64");
        let u8 = new Uint8Array(buffer);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(buffer),
            function(p, c) {
              return p + String.fromCharCode(c);
            },
            ""
          )
        );
        let mimetype = "application/pdf";
        console.log(b64encoded);

        var loadingTask = vuePdf.createLoadingTask(
          "data:" + mimetype + ";base64," + b64encoded
        );
        this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
        this.filearraybuffer = loadingTask;

        this.filearraybuffer.then(vuePdf => {
          this.pageCount = vuePdf.numPages;
        });

        this.loading = false;
      } catch (ex) {
        console.log(ex);
      }
    },
   async downloadfile(currentfile) {
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          type: "warning",
          title: "Coming Soon !!"
        });
      } else {
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.file_name;
        let auth = await gbfGenerate.generateToken();
        let url;
        if (this.filedata.status_watermark === "N" && this.folderdata.permission_share_link !== '01') {
          // let payload = {
          //   user_id: this.userid,
          //   file_id: currentfile.file_id
          // };
          url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/v2/download_file?user_id=" +
            "Shared link" +
            "&file_id=" +
            this.filedata.file_id;
        } else {
          url = 
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
            "/api/v1/share_link/download_file?file_id="+ 
            currentfile.file_id +
            "&language=" +
            this.$t("default") +
            "&link=" +
            this.$route.query.id;
            if (this.folderdata.private_share === true) {
              url = url + "&account_id=" + this.dataCitizenProfile.account_id;;
            }
        }
        this.axios
          .get(url, {
              headers: { Authorization: auth.code },
            onDownloadProgress: progressEvent => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // console.log(this.percentCompleted);   
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              }else{
                this.percentCompleted = progresspercent
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer"
          })
          .then(response => {
            // if (response.statusText === "OK") {
            if (response.status === 200) {
              try {
                let type = currentfile.file_name.split(".");
                type = type[type.length - 1].toLowerCase();
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                if (res_error.status === "ER" && type !== "json") {
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage
                  });
                } else {
                  this.percentCompleted = 100;
                  let headers = response.headers;
                  let blob = new Blob([response.data], {
                    type: headers["content-type"],
                  });
                  saveAs(blob, currentfile.file_name);
                }
              } catch (error) {
                this.percentCompleted = 100;
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                saveAs(blob, currentfile.file_name);
              }
              // if (
              //   parseInt(response.headers["content-lenght"]) < 700 &&
              //   parseInt(response.headers["content-type"]) === "application/json"
              // ) {
              //   this.percentCompleted = 95;
              //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              //   Toast.fire({
              //     icon: "error",
              //     title: res_error.errorMessage
              //   });
              // } else {
              //   this.percentCompleted = 100;
              //   // const blob = new Blob([response.data]);
              //   // const content = response.headers["content-type"];
              //   let headers = response.headers;
              //   let blob = new Blob([response.data], {
              //     type: headers["content-type"],
              //   });
              //   // let link = document.createElement("a");
              //   // link.href = window.URL.createObjectURL(blob);
              //   // link.download = currentfile.file_name;
              //   // link.click();
              //   saveAs(blob, currentfile.file_name);
              // }
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
            }, 2500);
          })
          .catch(error => {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              // icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
            });
            console.log(error);
          });
      }
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       type: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let payload = {
    //       user_id: this.userid,
    //       file_id: currentfile.file_id
    //     };
    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
    //         payload,
    //         {
    //           withCredentials: false,
    //           responseType: "blob"
    //         }
    //       )
    //       .then(response => {
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", currentfile.file_name); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //       })
    //       .catch(error => {
    //         Toast.fire({
    //           type: "error",
    //           title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
    //         });
    //         console.log(error);
    //       });
    //   }
    // },

    previewimage(parameter) {},

    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
    },
    closedialog() {
      this.$emit("closepreviewfile");
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = "";
      this.compatible = false;
      this.pageCount = 0;
      this.rawbase64 = "";
    },
         seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "mov" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    fn_onResize() {
      if (!(window.innerWidth >= this.playerOptions.width + 30 && window.innerWidth <= this.playerOptions.width + 100)) {
        this.playerOptions.height = window.innerHeight - 150;
        this.playerOptions.width = window.innerWidth - 70;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.fn_onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.fn_onResize);
  }
};
</script>

<style scoped>
img {
  -webkit-user-drag: none;
  user-drag: none;
}
#detailpreview {
  padding-top: 50px;
  padding-bottom: 25px;
  height: auto;
  overflow-y: auto;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70;
  left: 0;
}

#dialogpreview {
  background: rgba(0, 0, 0, 0.85);
}

.iframe-container {
  position: relative;
  padding-top: 47%;
  height: 0;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overscroll-behavior: contain;
}

#imageratio {
  height: 91.5vh;
  max-width: 99vw;
  width: 99vw;
  /* margin-bottom: 20px; */
  /* border: 1px solid gray; */
  display: flex; 
  justify-content: center; 
  align-items: center;
}

#iframeratio {
    height: 90vh; 
    max-width: 99vw; 
    width: 100vw;
    
  }

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #iframeratio {
    height: 73vh; 
    max-width: 99vw; 
    width: 100vw;
    
  }
}

.modal-contentc{
  padding-top: 10vh;
  margin:auto;
  display:block;
  max-width: 90vw !important; 
  max-height: 70vh !important;
}

/* watermarked iframe */
.watermarked:after {
  color: rgba(0, 0, 0, 0.3);
  /* color: white; */
  content: attr(data-watermark) '\a' attr(data-watermark2) '\a' attr(data-watermark3);
  white-space: pre;
  transform: rotate(-45deg);
  font-size: 15px;
  /* font-weight: bold; */
  font-weight: normal;
  font-kerning: normal;
  letter-spacing: 1px;
  word-spacing: 1em;
  display: block;
  position: absolute;
  top: 0;
  left: -40%;
  /* filter: drop-shadow(2em 10em  white) drop-shadow(4em 20em  white) drop-shadow(8em 40em white) drop-shadow(-15em -80em white); */
  filter: drop-shadow(2em 10em  #000) drop-shadow(4em 20em  #000) drop-shadow(8em 40em #000) drop-shadow(-15em -80em #000);
  /* filter: drop-shadow(2em 6em  #000) drop-shadow(4em 12em  #000) drop-shadow(8em 24em #000) drop-shadow(-15em -48em #000); */
}
.watermarked {  
  /* width: max-content;
  display: grid; */
  overflow: hidden;
  /* height: min-content; */
  position: relative;
}
iframe, .watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
}
.flex.lg12 {
  max-width: max-content;
}

/* watermarked img */
.watermarkedImg:after {
  /* color: rgba(0, 0, 0, 0.3);
  content: attr(data-watermark) '\a' attr(data-watermark2); */
  /* color: white; */
  color: #c4c4c4;
  content: attr(data-watermark) '\a' attr(data-watermark2) '\a' attr(data-watermark3);
  white-space: pre;
  transform: rotate(-45deg);
  font-size: 15px;
  /* font-weight: bold; */
  font-weight: normal;
  font-kerning: normal;
  letter-spacing: 1px;
  word-spacing: 1em;
  position: absolute;
  top: 0;
  left: -40%;
  display: block;
  width: 150%;
  height: 150%;
  margin-top: -300px;
  margin-left: -300px;
  /* line-height: 6em; */
  /* filter: drop-shadow(2em 8em  #000) drop-shadow(4em 16em  #000) drop-shadow(8em 32em #000) drop-shadow(-15em 64em #000); */
  /* filter: drop-shadow(2em 8em  white) drop-shadow(4em 16em  white) drop-shadow(8em 32em white) drop-shadow(-15em 64em white); */
  filter: drop-shadow(2em 8em #c4c4c4) invert(90%) drop-shadow(4em 16em  #c4c4c4) invert(70%) drop-shadow(8em 32em #c4c4c4) invert(80%) drop-shadow(-15em 64em #c4c4c4) invert(60%);
}
.watermarkedImg {  
  position: relative;
  overflow: hidden;
}
.video-js{
  width: 50vw!important;
  height: 37vw!important;
}
.video-js .vjs-big-play-button {
  top: 45%;
  left: 45%;
}
@media only screen and (max-width: 960px) {
  .video-js .vjs-big-play-button {
    font-size: 2em;
  }
}
@media only screen and (max-width: 600px) {
  .video-js .vjs-big-play-button {
    font-size: 1em;
  }
}
</style>

